import React from "react";
import BCard from "./BenefitsCard";
import {
  cellLineBenefitsData,
  productionBenefitsData,
  scaleBenefitsData,
} from "../../data/marketing-data";
const BenefitsSection = () => {
  //   const imageData = useStaticQuery(
  //     graphql`
  //       query {
  //         abstractDnaLight: imgixImage(
  //           url: "/cell-benefits/abstract-dna-light.jpg"
  //         ) {
  //           gatsbyImageData(
  //             aspectRatio: 1
  //             layout: CONSTRAINED
  //             srcSetMaxWidth: 1200
  //             imgixParams: { crop: "bottom", fit: "crop" }
  //           )
  //         }
  //         acetohydroxyacidSynthaseTransparent: imgixImage(
  //           url: "/cell-benefits/acetohydroxyacid-synthase-transparent.png"
  //         ) {
  //           gatsbyImageData(
  //             aspectRatio: 1
  //             layout: CONSTRAINED
  //             srcSetMaxWidth: 1200
  //             placeholder: BLURRED
  //             imgixParams: { crop: "bottom", fit: "crop" }
  //           )
  //         }
  //         cellProteinRenderBkgRemoved: imgixImage(
  //           url: "/cell-benefits/cell-protein-render-bkgRemoved.png"
  //         ) {
  //           gatsbyImageData(
  //             aspectRatio: 1
  //             layout: CONSTRAINED
  //             srcSetMaxWidth: 1200
  //             placeholder: BLURRED
  //             imgixParams: { crop: "bottom", fit: "crop" }
  //           )
  //         }
  //         crystalHemoglobinBkgRemoved: imgixImage(
  //           url: "/cell-benefits/crystal-hemoglobin-bkgRemoved.png"
  //         ) {
  //           gatsbyImageData(
  //             aspectRatio: 1
  //             layout: CONSTRAINED
  //             srcSetMaxWidth: 1200
  //             placeholder: BLURRED
  //             imgixParams: { crop: "bottom", fit: "crop" }
  //           )
  //         }
  //         bluePlummage: imgixImage(url: "/cell-benefits/blue-plummage.jpg") {
  //           gatsbyImageData(
  //             aspectRatio: 1
  //             layout: CONSTRAINED
  //             srcSetMaxWidth: 1200
  //             imgixParams: { crop: "bottom", fit: "crop" }
  //           )
  //         }
  //         mitochondriaFibers: imgixImage(
  //           url: "/cell-benefits/mitochondria-fibers.jpg"
  //         ) {
  //           gatsbyImageData(
  //             aspectRatio: 1
  //             layout: CONSTRAINED
  //             srcSetMaxWidth: 1200
  //             imgixParams: { crop: "bottom", fit: "crop" }
  //           )
  //         }
  //         waterDrop: imgixImage(url: "/cell-benefits/water-drop.jpg") {
  //           gatsbyImageData(
  //             aspectRatio: 1
  //             layout: CONSTRAINED
  //             srcSetMaxWidth: 1600
  //             backgroundColor: "black"
  //             imgixParams: { crop: "bottom", fit: "crop" }
  //           )
  //         }
  //         abstractFibersTransparent: imgixImage(
  //           url: "/cell-benefits/abstract-fibers-transparent.png"
  //         ) {
  //           gatsbyImageData(
  //             aspectRatio: 1
  //             layout: CONSTRAINED
  //             width: 1600
  //             height: 1600
  //             backgroundColor: "black"
  //             imgixParams: { crop: "bottom", fit: "crop" }
  //           )
  //         }
  //         microRnaDelivery: imgixImage(
  //           url: "/cell-benefits/micro-rna-delivery.jpg"
  //         ) {
  //           gatsbyImageData(
  //             aspectRatio: 1
  //             layout: CONSTRAINED
  //             srcSetMaxWidth: 1600
  //             backgroundColor: "black"
  //             imgixParams: { crop: "bottom", fit: "crop" }
  //           )
  //         }
  //         snakeRender: imgixImage(url: "/cell-benefits/snake-render.jpg") {
  //           gatsbyImageData(
  //             aspectRatio: 1.77777
  //             layout: CONSTRAINED
  //             srcSetMaxWidth: 1600
  //             backgroundColor: "black"
  //             imgixParams: { crop: "bottom", fit: "crop" }
  //           )
  //         }
  //         glassCellAbstract: imgixImage(
  //           url: "/cell-benefits/glass-cell-abstract.jpg"
  //         ) {
  //           gatsbyImageData(
  //             aspectRatio: 1
  //             layout: CONSTRAINED
  //             srcSetMaxWidth: 1600
  //             backgroundColor: "black"
  //             imgixParams: { crop: "bottom", fit: "crop" }
  //           )
  //         }
  //         ducklingsDrinking: imgixImage(
  //           url: "/cell-benefits/ducklings-drinking.jpg"
  //         ) {
  //           gatsbyImageData(
  //             aspectRatio: 1
  //             layout: CONSTRAINED
  //             srcSetMaxWidth: 1200
  //             imgixParams: { crop: "bottom", fit: "crop" }
  //           )
  //         }
  //         italianFamilyEatingOutside: imgixImage(
  //           url: "/cell-benefits/italian-family-eating-outside.jpg"
  //         ) {
  //           gatsbyImageData(
  //             aspectRatio: 1
  //             layout: CONSTRAINED
  //             srcSetMaxWidth: 1200
  //             imgixParams: { crop: "bottom", fit: "crop" }
  //           )
  //         }
  //         earthBlack: imgixImage(url: "/cell-benefits/earth-black.jpg") {
  //           gatsbyImageData(
  //             aspectRatio: 1
  //             layout: CONSTRAINED
  //             srcSetMaxWidth: 1200
  //             backgroundColor: "black"
  //             imgixParams: { crop: "bottom", fit: "crop" }
  //           )
  //         }
  //       }
  //     `
  //   );
  return (
    <div className="pt-8 pb-16 mx-auto xl:container">
      <div className="px-16 mt-8 mb-4 xl:px-8">
        <h3 className="text-3xl font-bold text-blue-500 lg:text-4xl light-plx">
          Research
        </h3>
      </div>
      <ul className="grid gap-8 px-8 xl:px-0">
        {cellLineBenefitsData.map(
          ({ benefit, description, type, uri, image, span, style, fit }) => (
            <BCard
              benefit={benefit}
              description={description}
              type={type}
              key={benefit}
              span={span}
              uri={`https://cultura.imgix.net/cell-benefits/${uri}`}
              style={style}
              fit={fit}
            />
          )
        )}
      </ul>
      <div className="px-16 mt-8 mb-4 xl:px-8">
        <h3 className="text-3xl font-bold text-purple-500 lg:text-4xl dark-plx-2">
          Cultivate
        </h3>
      </div>
      <ul className="grid gap-8 px-8 xl:px-0">
        {scaleBenefitsData.map(
          ({ benefit, description, type, uri, span, style, fit }) => (
            <BCard
              benefit={benefit}
              description={description}
              type={type}
              key={benefit}
              span={span}
              uri={`https://cultura.imgix.net/cell-benefits/${uri}`}
              style={style}
              fit={fit}
            />
          )
        )}
      </ul>
      <div className="px-16 mt-8 mb-4 xl:px-8">
        <h3 className="text-3xl font-bold text-green-500 lg:text-4xl light-plx-2">
          Scale
        </h3>
      </div>
      <ul className="grid gap-8 px-8 xl:px-0">
        {productionBenefitsData.map(
          ({ benefit, description, type, uri, span, style, fit }) => (
            <BCard
              benefit={benefit}
              description={description}
              type={type}
              key={benefit}
              span={span}
              uri={`https://cultura.imgix.net/cell-benefits/${uri}`}
              style={style}
              fit={fit}
            />
          )
        )}
      </ul>
    </div>
  );
};

export default BenefitsSection;
