import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import CtaButton from "../shared/buttons/CtaButton";
import { waitlistCta } from "../../data/ctas";
import Plx from "react-plx";
import {
  HeroPlxBackgroundData,
  HeroPlxTagOneData,
  HeroPlxTagTwoData,
  HeroPlxTagThreeData,
} from "../../plx/lander-plx-triggers-data";
import { GatsbyImage } from "gatsby-plugin-image";
const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: imgixImage(url: "/cell-benefits/chicken-white.jpg") {
        gatsbyImageData(
          aspectRatio: 1.3
          layout: CONSTRAINED
          imgixParams: { crop: "left", fit: "crop" }
        )
      }
    }
  `);

  const heroImage = data.hero.gatsbyImageData;
  return (
    <section>
      <Plx
        className="relative z-0 flex flex-col h-[300vh] bg-white text-black"
        parallaxData={HeroPlxBackgroundData}
      >
        <Plx
          className="sticky flex flex-grow-0 w-full h-screen top-16"
          parallaxData={[
            {
              start: "170vh",
              end: ".pe",
              endDelay: "10vh",
              easing: "easeInQuad",
              properties: [
                {
                  startValue: 1,
                  endValue: 0,
                  property: "opacity",
                },
              ],
            },
          ]}
        >
          <div className="w-full flex h-[80vh]">
            <div className="flex flex-col w-full m-auto space-y-2 md:space-y-4">
              <div className="container mx-auto">
                <div className="relative flex w-auto ">
                  <h1 className="mx-auto text-2xl font-bold tracking-tighter text-transparent xl:text-4xl bg-gradient-to-r from-orange-500 via-pink-500 to-purple-500 bg-clip-text">
                    Cultura Cells
                  </h1>
                </div>
                <div className="relative h-24 text-4xl font-bold tracking-tighter xl:text-6xl">
                  <Plx
                    parallaxData={HeroPlxTagOneData}
                    className="absolute w-full px-8 text-center sm:px-12"
                    tagName="h2"
                  >
                    Cell Lines for Cultivators
                  </Plx>
                  <Plx
                    parallaxData={HeroPlxTagTwoData}
                    className="absolute w-full px-8 mx-auto text-center opacity-0 sm:px-12"
                    tagName="h2"
                  >
                    Cultivate Without Compromise
                  </Plx>
                  <Plx
                    parallaxData={HeroPlxTagThreeData}
                    className="absolute w-full px-8 mx-auto text-center opacity-0 sm:px-12"
                    tagName="h2"
                  >
                    Expand Beyond Capable
                  </Plx>
                </div>
              </div>
              <div className="relative flex h-[40vh] ">
                <Plx
                  className="opacity-100"
                  parallaxData={[
                    {
                      start: "0vh",
                      duration: "70vh",
                      properties: [
                        {
                          startValue: 1,
                          endValue: 0,
                          property: "opacity",
                        },
                      ],
                    },
                  ]}
                >
                  <GatsbyImage
                    image={heroImage}
                    objectFit="contain"
                    className="max-h-[40vh]"
                    alt="Chicken"
                    loading="eager"
                  />
                </Plx>
                <Plx
                  className="absolute inset-0 flex px-12 opacity-0"
                  parallaxData={[
                    {
                      start: "40vh",
                      duration: "70vh",
                      properties: [
                        {
                          startValue: 0,
                          endValue: 1,
                          property: "opacity",
                        },
                      ],
                    },
                  ]}
                >
                  <p className="container mx-auto text-2xl font-semibold text-center xl:max-w-4xl lg:text-3xl xl:text-4xl bg-gradient-to-r from-orange-500 via-pink-500 to-purple-500 bg-clip-text">
                    Cultura Cells are discovered, selected, and engineered to be{" "}
                    <Plx
                      tagName="span"
                      parallaxData={[
                        {
                          start: "110vh",
                          end: "140vh",
                          endOffset: 200,
                          properties: [
                            {
                              startValue: "rgba(0,0,0, 1)",
                              endValue: "rgba(0,0,0, 0)",

                              property: "color",
                            },
                          ],
                        },
                      ]}
                      className="text-black"
                    >
                      the world's most efficient cells for cultured meat.
                    </Plx>
                  </p>
                </Plx>
              </div>
              <div className="flex w-full">
                <CtaButton
                  variant="gradient"
                  to="/waitlist/"
                  className="w-auto mx-auto"
                >
                  {waitlistCta}
                </CtaButton>
              </div>
            </div>
          </div>
        </Plx>
      </Plx>
    </section>
  );
};

export default Hero;
