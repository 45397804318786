export const cellLineBenefitsData = [
  {
    benefit: "Next Generation Sequencing",
    description:
      "Second-generation sequencing is the most cost-effective way to discover new cell genotypes. These strongly performing genotypes can be verified for phenotypic expression and consolidated for compounded impact to cell line performance.",
    type: "Pilot",
    uri: "abstract-dna-light.jpg",
    image: "abstractDnaLight",
    alt: "mouse dna sequence",
    span: "xl:col-span-1",
    style: "white",
    fit: "cover",
  },
  {
    benefit: "Cell-Line-Specific Gene Expression Profiles",
    description:
      "Cultura uses DNA Microarray technology to analyze and tune gene expression profiles.",
    type: "Pilot",
    uri: "acetohydroxyacid-synthase-transparent.png",
    image: "acetohydroxyacidSynthaseTransparent",
    style: "white",
  },
  {
    benefit: "Multi-Omic Discovery",
    description:
      "Blotting, ChIP, RNA-Seq & protein expression analysis are used to map out a more complete multi-omic understanding of Cultura Cells individual metabolic profiles and phenotypic expressions.",
    type: "Pilot",
    uri: "cell-protein-render-bkgRemoved.png",
    image: "cellProteinRenderBkgRemoved",
    style: "white",
  },
  {
    benefit: "Tunable Epigenetic Modulation",
    description:
      "Bisulfite deamination & generation of 5mC methylation profiles in conjunction with state-of-the-art methylation tuning provides Cultura the opportunity to impact epigenetic expression without altering genetic sequences.",
    type: "Pilot",
    uri: "crystal-hemoglobin-bkgRemoved.png",
    image: "crystalHemoglobinBkgRemoved",
    span: "xl:col-span-1",
    style: "white",
  },
  {
    benefit: "Metabolic & Signaling Pathway Analysis",
    description:
      "Cultura is able to identify and quantitate limiting factors in the metabolic and signaling pathways of Selected & Engineered Cells.",
    type: "Pilot",
    span: "xl:col-span-1",
    uri: "blue-plummage.jpg",
    image: "bluePlummage",
    style: "white",
    fit: "cover",
  },

  {
    benefit: "Modern Molecular Biological Assays",
    description:
      "Cultura incorporates a wide range of biological assays & biochemical analytics to analyze the data and implement feature developments for our product pipelines.",
    type: "Pilot",
    uri: "mitochondria-fibers.jpg",
    image: "mitochondriaFibers",
    style: "white",
    fit: "cover",
  },
  {
    benefit: "Tailored Media",
    description:
      "Cultura tests numerous animal-derived-component free media formulations with Cultura Cells and provides recommendations for media use to Cultivators based on the cell line.",
    type: "Pilot",
    uri: "water-drop.jpg",
    image: "waterDrop",
    style: "white",
    fit: "cover",
    span: "lg:col-span-1 xl:col-span-1",
  },
];
export const scaleBenefitsData = [
  {
    benefit: "Faster Cell Proliferation",
    description:
      "Cultura Cells proliferate rapidly and differentiate to develop proper morphology and tissue structure.",
    type: "Scale",
    uri: "abstract-fibers-transparent.png",
    image: "abstractFibersTransparent",
    style: "glass",
  },
  {
    benefit: "Improved Cell Density",
    description:
      "Cultura Cells can grow to and thrive in higher maximum cell densities than other cells of the same cell types. This results in higher wet cell mass yields and reduced bioreactor sizing requirements.",
    type: "Scale",
    uri: "micro-rna-delivery.jpg",
    image: "microRnaDelivery",
    style: "glass",
  },
  {
    benefit: "Improved Cell Viability",
    description:
      "Cultura Cells are more resistant to damage and can survive longer than other cells.",
    type: "Scale",
    uri: "snake-render.jpg",
    image: "snakeRender",
    style: "glass",
  },
  {
    benefit: "Higher Waste Tolerance",
    description:
      "Cultura Cells tolerate higher accumulated waste concentrations, promoting strong growth conditions in conjunction with media recycling. ",
    type: "Scale",
    uri: "glass-cell-abstract.jpg",
    image: "glassCellAbstract",
    style: "glass",
  },
];

// uri: "botanical-colony.jpg",

export const productionBenefitsData = [
  {
    benefit: "Ethically-Sourced Cell Lines",
    description:
      "Cultura Animals are never harmed during initial cell acquisition and cell lines are extensively tested to ensure safety.",
    type: "Production",
    span: "lg:col-span-1",
    uri: "ducklings-drinking.jpg",
    image: "ducklingsDrinking",
    style: "white",
    fit: "cover",
  },
  {
    benefit: "Localized Cell Lines",
    description: "Cultura Cells meet local laws and regulations.",
    type: "Production",
    uri: "italian-family-eating-outside.jpg",
    image: "italianFamilyEatingOutside",
    style: "white",
    fit: "cover",
  },
  {
    benefit: "Global Distribution",
    description:
      "Cultura Cells are distributed to countries all around the world.",
    type: "Production",
    uri: "earth-black.jpg",
    image: "earthBlack",
    style: "white",
    fit: "cover",
  },
];

// Mention coverage of the cell lines and exotic line availability
export const speciesSectionData = {
  salesHeading: "Five Distinct Species",
};

export const cellLineTypeSectionData = {
  salesHeading: "Full Cell Type Coverage",
};

// Talk about purchase from pilot to production -- meeting localized regulations
export const distributionCoverageSectionData = {
  salesHeading: "Globally Available, Locally Scalable.",
};
