const startOffset = 500;
const duration = 100;
const dark = "rgba(0, 0, 0, 1)";
const light = "rgba(255,255,255, 1)";

const bottomBorderDark = "rgba(0, 0, 0, 1)";
const bottomBorderLight = "#e4e4e7";
const subnavBackgroundDark = "rgba(0, 0, 0, 0.75)";
const subnavBackgroundLight = "rgba(255,255,255, 0.75)";

export const plxDataBase = (start, hasOffset, properties, customDuration) => {
  return {
    start: start,
    duration: customDuration ? customDuration : duration,
    startOffset: hasOffset ? startOffset : 0,
    properties: properties,
  };
};
const plxPropertyDataBase = (startValue, endValue, property) => {
  return { startValue: startValue, endValue: endValue, property: property };
};
const plxPropertyDarkBackground = plxPropertyDataBase(
  light,
  dark,
  "backgroundColor"
);
const plxPropertyLightBackground = plxPropertyDataBase(
  dark,
  light,
  "backgroundColor"
);

const plxPropertyLightToDarkColor = plxPropertyDataBase(light, dark, "color");
const plxPropertyDarkToLightColor = plxPropertyDataBase(dark, light, "color");
export const plxPropertyOpacityToOne = [plxPropertyDataBase(0, 1, "opacity")];
export const plxPropertyOpacityToZero = [plxPropertyDataBase(1, 0, "opacity")];
const plxPropertySubnavDarkToLight = plxPropertyDataBase(
  subnavBackgroundLight,
  subnavBackgroundDark,
  "backgroundColor"
);
const plxPropertySubnavLightToDark = plxPropertyDataBase(
  subnavBackgroundDark,
  subnavBackgroundLight,
  "backgroundColor"
);
const plxPropertySubnavBottomBorderLightToDark = plxPropertyDataBase(
  light,
  bottomBorderDark,
  "borderBottomColor"
);
const plxPropertySubnavBottomBorderDarkToLight = plxPropertyDataBase(
  dark,
  bottomBorderLight,
  "borderBottomColor"
);

export const plxPropertiesDarkToLight = [
  plxPropertyDarkBackground,
  plxPropertyDarkToLightColor,
];

export const plxPropertiesLightToDark = [
  plxPropertyLightBackground,
  plxPropertyLightToDarkColor,
];

export const plxPropertiesSubNavDarkToLight = [
  plxPropertySubnavDarkToLight,
  plxPropertySubnavBottomBorderDarkToLight,
];

export const plxPropertiesSubNavLightToDark = [
  plxPropertySubnavLightToDark,
  plxPropertySubnavBottomBorderLightToDark,
];
