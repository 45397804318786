import {
  plxDataBase,
  plxPropertiesDarkToLight,
  plxPropertyOpacityToZero,
  plxPropertyOpacityToOne,
} from "./base-plx-data.js";
const starts = { 0: "0vh", 70: "70vh", 140: "140vh" };
export const HeroPlxBackgroundData = [
  plxDataBase(starts[140], false, plxPropertiesDarkToLight, "200"),
];
export const HeroPlxTagOneData = [
  plxDataBase(starts[0], false, plxPropertyOpacityToZero, "70vh"),
];
export const HeroPlxTagTwoData = [
  plxDataBase(starts[0], false, plxPropertyOpacityToOne, "70vh"),
  plxDataBase(starts[140], false, plxPropertyOpacityToZero),
];
export const HeroPlxTagThreeData = [
  plxDataBase(starts[140], false, plxPropertyOpacityToOne),
];
