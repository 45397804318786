import React from "react";
import { SecondaryBody, SecondaryHeading } from "../shared/Headings";
import { speciesCommonNameMapping } from "../../data/cell-lines";
import PlxTextColor from "../plx/PlxTextColor";

const SpeciesCommonNameSection = () => {
  return (
    <section className="flex flex-col px-8 py-16 bg-black" id="species">
      <div className="xl:mx-auto xl:container rounded-3xl pe">
        <div className="px-8 py-8 xl:py-12 bg-zinc-100 rounded-t-3xl">
          <SecondaryHeading additionalClassNames="w-full font-semibold text-slate-900 text-5xl lg:text-8xl xl:max-w-xl">
            5+ Species
          </SecondaryHeading>
          <SecondaryBody additionalClassNames="text-lg font-semibold lg:text-xl xl:text-2xl">
            Cultura Cells are currently available in 5 species — covering up to
            80% of today's global cultured meat production.{" "}
          </SecondaryBody>
        </div>

        <ul className="grid w-full px-8 py-8 space-y-4 xl:py-12 xl:space-y-12 xl:container bg-zinc-900 rounded-b-3xl">
          {speciesCommonNameMapping.map((data) => (
            <li key={data[0] + "_" + data[1]} to={"/" + data[0].toLowerCase()}>
              <PlxTextColor
                className="text-3xl font-bold lg:text-6xl text-zinc-100"
                tagName="h3"
                startValue="#e4e4e7"
                endValue="rgba(0, 255, 255, 1)"
              >
                {data[0]}
              </PlxTextColor>
              <p className="text-zinc-200 lg:text-2xl lg:mt-1">{data[1]}</p>
            </li>
          ))}

          <li>
            <PlxTextColor
              className="text-3xl font-bold text-white lg:text-6xl"
              tagName="h3"
              startValue="#e4e4e7"
              endValue="rgba(0, 255, 255, 1)"
            >
              Exotic
            </PlxTextColor>
            <p className="text-zinc-200 lg:text-2xl lg:mt-1">Coming Soon</p>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default SpeciesCommonNameSection;
