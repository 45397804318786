import React from "react";
import classNames from "../../utils/twClassNames";
import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImageData } from "@imgix/gatsby/src/modules/gatsby-transform-url/gatsbyPluginImage";
import Plx from "react-plx";
const colorConvMap = {
  Pilot: "blue",
  Scale: "purple",
  Production: "green",
};

const plxData = [
  {
    start: "self",
    duration: 500,
    easing: "easeInQuad",
    properties: [
      {
        startValue: 112,
        endValue: 0,
        property: "translateY",
      },
    ],
  },
];

const BCard = ({
  benefit,
  description,
  type,
  uri,
  span,
  style,
  fit,
  image,
}) => {
  return (
    <>
      {style === "glass" ? (
        <BenefitsCardGlass
          benefit={benefit}
          description={description}
          type={type}
          key={benefit}
          span={span}
          uri={uri}
          image={image}
        />
      ) : (
        <BenefitsCardWhite
          benefit={benefit}
          description={description}
          type={type}
          key={benefit}
          span={span}
          uri={uri}
          fit={fit}
          image={image}
        />
      )}
    </>
  );
};
const BenefitsCardGlass = ({ benefit, description, type, uri, span }) => {
  // Not sure why tailwind isn't compiling the colors
  // TODO: Fix this hacky div solution
  const textColor = colorConvMap[type];
  return (
    <Plx
      className={classNames(
        "items-center rounded-3xl h-116 xl:h-150 overflow-none relative z-0 flex bg-black  transform-gpu   translate-y-28",
        span && span
      )}
      parallaxData={plxData}
      tagName="li"
    >
      <div className="flex flex-col w-full h-auto p-8 mb-auto rounded-3xl backdrop-blur-3xl">
        <h3
          className={`text-3xl font-bold tracking-tighter text-left text-${textColor}-500 lg:text-4xl`}
        >
          {benefit}
        </h3>
        <p className="mt-1 text-lg font-semibold text-white lg:text-xl">
          {description}
        </p>
      </div>

      <div className="absolute flex w-full h-full rounded-md">
        <GatsbyImage
          image={getGatsbyImageData({
            src: uri,
            aspectRatio: 1,
            sourceWidth: 1200,
            sourceHeight: 1200,
            imgixParams: { auto: ["format", "compress"], crop: "bottom" },
            layout: "constrained",
          })}
          className="w-full h-full rounded-3xl -z-10"
          alt=""
        />
      </div>
    </Plx>
  );
};

const BenefitsCardWhite = ({ benefit, description, type, span, fit, uri }) => {
  const textColor = colorConvMap[type];
  return (
    <Plx
      className={classNames(
        "rounded-3xl h-116 xl:h-150 overflow-none w-116 flex bg-white flex-col xl:flex-row",
        span && span
      )}
      parallaxData={plxData}
      tagName="li"
    >
      <div className="flex w-full h-full rounded-l-3xl">
        <div className="flex flex-col w-full p-8">
          <h3
            className={`text-3xl font-bold tracking-tighter text-left text-${textColor}-500 lg:text-4xl`}
          >
            {benefit}
          </h3>
          <p className="mt-1 text-lg font-semibold text-slate-900 lg:text-xl">
            {description}
          </p>
        </div>
      </div>

      <GatsbyImage
        image={getGatsbyImageData({
          src: uri,
          aspectRatio: 1,
          sourceWidth: 1600,
          sourceHeight: 1600,
          imgixParams: { auto: ["format", "compress"], crop: "bottom" },
          layout: "constrained",
        })}
        imgClassName="rounded-bl-3xl rounded-br-3xl xl:rounded-bl-none xl:rounded-tr-3xl"
        className="flex w-full h-full rounded-bl-3xl rounded-br-3xl xl:rounded-bl-none xl:rounded-tr-3xl"
        alt=""
        objectFit={fit ? fit : "contain"}
      />
    </Plx>
  );
};

export default BCard;
