import React from "react";
import CellLineTypesDetailsSection from "./CellLineTypesDetailsSection";
import Plx from "react-plx";
import PlxTextColor from "../plx/PlxTextColor";
const CellLineTypesSection = () => {
  return (
    <Plx
      className="relative flex flex-col px-8 py-16 bg-black dark-plx"
      id="cell-line-types"
      tagName="section"
      parallaxData={[
        {
          start: ".light-plx",
          duration: 100,
          startOffset: 500,
          properties: [
            {
              startValue: "#000000",
              endValue: "#f4f4f5",
              property: "backgroundColor",
            },
            {
              startValue: "#f4f4f5",
              endValue: "#000000",
              property: "color",
            },
          ],
        },
      ]}
    >
      <div className="w-full h-full mx-auto xl:container">
        <div className="w-full px-8 mb-8 xl:px-8">
          <h2 className="w-full max-w-xs pb-3 my-auto text-5xl font-semibold tracking-tight text-white md:text-7xl lg:text-8xl md:max-w-lg xl:max-w-xl bg-clip-text bg-gradient-to-b from-blue-600 via-pink-600 to-purple-600 ">
            <PlxTextColor
              className="text-3xl text-transparent md:text-5xl lg:text-6xl "
              tagName="span"
              startValue="rgba(255,255,255,0)"
              endValue="rgba(255,255,255,1)"
            >
              Cultura Cells
            </PlxTextColor>{" "}
            <PlxTextColor
              className="text-transparent"
              tagName="span"
              startValue="rgba(255,255,255,0)"
              endValue="rgba(255,255,255,1)"
            >
              Unleash
            </PlxTextColor>{" "}
            <PlxTextColor
              className="text-transparent "
              tagName="span"
              startValue="rgba(255,255,255,0)"
              endValue="rgba(255,255,255,1)"
            >
              Genetic
            </PlxTextColor>{" "}
            <PlxTextColor
              className="text-transparent "
              tagName="span"
              startValue="rgba(255,255,255,0)"
              endValue="rgba(255,255,255,1)"
            >
              Potential
            </PlxTextColor>
          </h2>
        </div>
      </div>
      <CellLineTypesDetailsSection />
    </Plx>
  );
};

export default CellLineTypesSection;
