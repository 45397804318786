import React from "react";
import Layout from "../components/shared/Layout";
import Seo from "../components/seo/Seo";
import { socialShareCardImage } from "../imgix/social-card";
import SpeciesCommonNameSection from "../components/lander/SpeciesCommonNameSection";
import Hero from "../components/lander/Hero";
import CellLineTypesSection from "../components/lander/CellLineTypesSection";
import TalkingPointsSection from "../components/lander/TalkingPointsSection";
export default function index() {
  const socialImage = socialShareCardImage(
    "hero.jpg",
    "Cell Lines for Cultured Meat"
  );
  return (
    <Layout isAnimated={true}>
      <Seo
        title="Cell Lines for Cultured Meat"
        image={{
          src: socialImage,
          width: 1200,
          height: 630,
        }}
      />
      <Hero />
      <SpeciesCommonNameSection />
      <CellLineTypesSection />
      <TalkingPointsSection />
    </Layout>
  );
}

// pilot to production
// testing & validation
// continuous improvement
// international distribution
// specific lines vary based on local laws & regulations
// Pilot
// Cultura accelerates your research.
// Scale
// Cultura cells significantly reduce cost of production.
// Production
// Cultura extends your reach with globally distributed cell lines and localized lines for specific markets.
