import React from "react";
import Plx from "react-plx";
import BenefitsSection from "./BenefitsSection";

const plxData = [
  {
    start: ".light-plx",
    duration: 100,
    startOffset: 500,
    properties: [
      {
        startValue: "#000000",
        endValue: "#f4f4f5",
        property: "backgroundColor",
      },
      {
        startValue: "#f4f4f5",
        endValue: "#000000",
        property: "color",
      },
    ],
  },
  {
    start: ".dark-plx-2",
    duration: 100,
    startOffset: 500,
    properties: [
      {
        startValue: "#f4f4f5",
        endValue: "#000000",
        property: "backgroundColor",
      },
    ],
  },
  {
    start: ".light-plx-2",
    duration: 100,
    startOffset: 500,
    properties: [
      {
        startValue: "#000000",
        endValue: "#f4f4f5",
        property: "backgroundColor",
      },
      {
        startValue: "#f4f4f5",
        endValue: "#000000",
        property: "color",
      },
    ],
  },
];

const TalkingPointsSection = () => {
  return (
    <Plx
      className="flex flex-col py-16 bg-black text-zinc-100"
      id="benefits"
      parallaxData={plxData}
      tagName="section"
    >
      <div className="xl:mx-auto xl:container">
        <div className="w-full px-16 xl:px-8 lg:grid lg:grid-cols-2">
          <Plx
            className="text-5xl font-semibold tracking-tight text-transparent opacity-0 bg-clip-text bg-gradient-to-br from-blue-600 via-purple-600 to-pink-600 lg:text-6xl xl:text-8xl"
            parallaxData={[
              {
                start: "self",
                startDelay: "15vh",
                duration: "20vh",
                properties: [
                  {
                    startValue: 0,
                    endValue: 1,
                    property: "opacity",
                  },
                ],
              },
            ]}
            tagName="h2"
          >
            Pilot to Production
          </Plx>
          <Plx
            className="mt-2 text-lg font-semibold opacity-0 lg:text-xl xl:text-3xl lg:col-start-1"
            parallaxData={[
              {
                start: "self",
                startDelay: "15vh",
                duration: "20vh",
                properties: [
                  {
                    startValue: 0,
                    endValue: 1,
                    property: "opacity",
                  },
                ],
              },
            ]}
            tagName="p"
          >
            Cultura accelerates your research by delivering unparalleled cell
            line performance, the tools to validate your results and the
            localized lines necessary for international distribution.
          </Plx>
        </div>
      </div>
      <BenefitsSection />
    </Plx>
  );
};

export default TalkingPointsSection;
