import React from "react";
import Plx from "react-plx";
import PlxTextColor from "../plx/PlxTextColor";

const PlxSpanWapper = ({ children }) => {
  return (
    <PlxTextColor
      className="text-zinc-900"
      tagName="span"
      startValue="#18181b"
      endValue="rgba(0, 0, 0, 0)"
    >
      {children}
    </PlxTextColor>
  );
};

const cellLineTypesLanderData = [
  {
    type: "Discovered",
    photoUri: "discovered.svg",
    tagline: "Wildly Unique",
    description: (
      <>
        Cells that have been discovered in the wild.{" "}
        <PlxSpanWapper>
          Discovered lines perform up to 2x better than randomized lines
        </PlxSpanWapper>{" "}
        and undergo rigorous testing before large scale application.
      </>
    ),
    mainMetric: "Higher Cell Density",
    mainMetricImpact: "1.5",
    densityImpact: "1.1",
    gradient: "from-emerald-500 via-green-500 to-sky-400",
  },
  {
    type: "Selected",
    photoUri: "selected.svg",
    tagline: "Natural Acceleration",
    description: (
      <>
        Non-transgenic Cells orchestrated to deliver performance. Selected Cells
        have{" "}
        <PlxSpanWapper>
          achieved immortality without the use of direct gene manipulation.
        </PlxSpanWapper>{" "}
        Selected Cells are widely accepted for consumption in highly regulated
        production environments.
      </>
    ),
    mainMetric: "Higher Cell Density",
    mainMetricImpact: "2",
    densityImpact: "1.3",
    gradient: "from-purple-600 via-red-600 to-pink-500",
  },
  {
    type: "Engineered",
    photoUri: "engineered.svg",
    tagline: "Beyond a Breakthrough",
    description: (
      <>
        Transgenic Cells designed, engineered and proven to surpass stringent
        performance standards.{" "}
        <PlxSpanWapper>
          Up to 125% faster proliferation rates than competing wild-type cells.
        </PlxSpanWapper>{" "}
        Improved flavor profiles, nutrient content & positive health impact.
      </>
    ),
    mainMetric: "Higher Cell Density",
    mainMetricImpact: "3",
    densityImpact: "2.1",
    gradient: "from-sky-500 via-purple-500 to-blue-600",
  },
];

const CellLineTypeDetailsCard = ({ cellLineType }) => {
  return (
    <Plx
      className="flex p-8 mx-auto bg-white xl:container rounded-3xl"
      tagName="li"
    >
      <div className={`grid lg:grid-cols-2`}>
        <div className="space-y-8">
          <div>
            <h3 className="text-xl font-bold tracking-tight xl:text-3xl 2xl:text-4xl text-zinc-900">
              {cellLineType.tagline}
            </h3>
            <PlxTextColor
              className={`pb-2 text-4xl font-bold tracking-tight text-zinc-900 xl:text-6xl 2xl:text-7xl bg-clip-text  bg-gradient-to-r ${cellLineType.gradient} `}
              tagName="h4"
              startValue="#18181b"
              endValue="rgba(0, 0, 0, 0)"
            >
              {cellLineType.type}
            </PlxTextColor>
          </div>

          <p
            className={`pb-2 text-xl font-semibold xl:text-2xl 2xl:text-3xl text-zinc-900 bg-clip-text bg-gradient-to-r ${cellLineType.gradient}`}
          >
            {cellLineType.description}
          </p>
          <div>
            <PlxTextColor
              className={`text-4xl font-bold xl:text-6xl 2xl:text-7xl text-zinc-900 bg-clip-text  bg-gradient-to-r ${cellLineType.gradient} `}
              tagName="p"
              startValue="#18181b"
              endValue="rgba(0, 0, 0, 0)"
            >
              {cellLineType.mainMetricImpact}x
            </PlxTextColor>
            <p className="text-lg font-semibold text-zinc-500">
              {cellLineType.mainMetric}
            </p>
          </div>
        </div>
      </div>
    </Plx>
  );
};

const CellLineTypesDetailsSection = () => {
  return (
    <ul className="flex flex-col space-y-8 xl:space-y-12">
      {cellLineTypesLanderData.map((cellLineType) => (
        <CellLineTypeDetailsCard
          cellLineType={cellLineType}
          key={cellLineType.type}
        />
      ))}
    </ul>
  );
};

export default CellLineTypesDetailsSection;
