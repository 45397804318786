import React from "react";
import Plx from "react-plx/lib/Plx";

const PlxTextColor = ({
  startValue,
  endValue,
  tagName,
  className,
  children,
}) => {
  return (
    <Plx
      className={className}
      tagName={tagName}
      parallaxData={[
        {
          start: "self",
          startOffset: "30vh",
          duration: 100,
          properties: [
            {
              startValue,
              endValue,
              property: "color",
            },
          ],
        },
      ]}
    >
      {children}
    </Plx>
  );
};

export default PlxTextColor;
